import React, { useEffect, useState } from 'react'
import Burger from '../components/Burger'
import { Link } from 'gatsby'
import styled from 'styled-components'

const NavStyles = styled.nav`
  background: rgb(35, 35, 35);
  display: flex;
  padding: .5em 1em;

  @media (max-width: 768px) {
    div.links {
      position: fixed;
      top: 47px;
      left: 0;
      width: 100%;
      background: rgb(25, 25, 25);
      z-index: 2;
      transform: ${({ open }) => open ? 'translateY(0)' : 'translateY(-150%)'};
      opacity: ${({ open }) => open ? '1' : '0'};
      transition: all 0.5s;
      text-align: center;

      a {
        display: block;
        padding: 1em 0;
      }
    }
  }

  div:last-child {
    margin-left: auto;

    a:last-child {
      padding-right: 0;
    }
  }

  a {
    line-height: 30px;
    border: 0;
    color: white;
    padding-right: 2em;
    text-decoration: none;
  }
`

const Svg = styled.svg`
  width: 30px;
  fill: white;
  vertical-align: middle;
`

export default function Nav () {
  const dayNames = [
    'Sun', 'Mon', 'Tue', 'Wed',
    'Thu', 'Fri', 'Sat', 'Sun'
  ]
  const [dateTime, setDateTime] = useState(new Date())
  const [menuToggle, setMenuToggle] = useState(false)

  useEffect(() => {
    const id = setInterval(() => setDateTime(new Date()), 1000 * 60)

    return () => {
      clearInterval(id)
    }
  }, [])

  return (
    <NavStyles open={menuToggle}>
      <Burger open={menuToggle} onClick={() => setMenuToggle(!menuToggle)} />
      <div className='links'>
        <Link to='/'>Home</Link>
        <Link to='/projects'>Projects</Link>
        <Link to='/photography'>Photography</Link>
        <a href="mailto:i&#110;f&#111;&#64;d&#97;&#110;&#105;&#101;&#108;&#115;&#119;&#97;&#105;ne&#46;c&#111;&#46;u&#107;">
          Contact
        </a>
      </div>
      <div>
        <a href='https://github.com/danielswaine' target='_blank' rel='noreferrer'>
          <Svg role='img' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg'>
            <title>GitHub</title>
            <path d='M12 .297c-6.63 0-12 5.373-12 12 0 5.303 3.438 9.8 8.205 11.385.6.113.82-.258.82-.577 0-.285-.01-1.04-.015-2.04-3.338.724-4.042-1.61-4.042-1.61C4.422 18.07 3.633 17.7 3.633 17.7c-1.087-.744.084-.729.084-.729 1.205.084 1.838 1.236 1.838 1.236 1.07 1.835 2.809 1.305 3.495.998.108-.776.417-1.305.76-1.605-2.665-.3-5.466-1.332-5.466-5.93 0-1.31.465-2.38 1.235-3.22-.135-.303-.54-1.523.105-3.176 0 0 1.005-.322 3.3 1.23.96-.267 1.98-.399 3-.405 1.02.006 2.04.138 3 .405 2.28-1.552 3.285-1.23 3.285-1.23.645 1.653.24 2.873.12 3.176.765.84 1.23 1.91 1.23 3.22 0 4.61-2.805 5.625-5.475 5.92.42.36.81 1.096.81 2.22 0 1.606-.015 2.896-.015 3.286 0 .315.21.69.825.57C20.565 22.092 24 17.592 24 12.297c0-6.627-5.373-12-12-12'/>
          </Svg>
        </a>
        <Link to='#'>
          {dayNames[dateTime.getDay()]}{' '}
          {`${dateTime.getHours()}`.padStart(2, '0')}:
          {`${dateTime.getMinutes()}`.padStart(2, '0')}
        </Link>
      </div>
    </NavStyles>
  )
}

import { graphql, useStaticQuery } from 'gatsby'
import Favicon from '../images/favicon.ico'
import { Helmet } from 'react-helmet'
import React from 'react'

function Seo ({ title, description, lang, meta }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
          }
        }
      }
    `
  )

  const metaDescription = description || site.siteMetadata.description

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      meta={[
        {
          name: 'description', content: metaDescription,
        },
        {
          name: 'google-site-verification',
          content: 'eMKKE-q-tq89PhioNLWdktMPvCkvRaHG6_gZs0VxWSY'
        }
      ]}
      link={[
        {
          'rel': 'shortcut icon',
          'href': `${Favicon}`
        }
      ]}
    />
  )
}

Seo.defaultProps = {
  lang: 'en',
  meta: [],
  description: '',
}

export default Seo

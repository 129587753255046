import styled, { css } from 'styled-components'

const AppBorderStyles = css`
  text-align: left;
  border: 0;
  box-shadow: 0 0 30px rgba(0,0,0,0.8);
  overflow: hidden;
  border-radius: 5px;
  position: fixed;
	top: 50%;
	left: 25%;
  right: 25%;
	height: 50%;
	transform: translateY(-50%);
  transition: all 0.5s;
  z-index: 1;

  &.full {
    left: 0;
    right: 0;
    height: 100%;
  }

  &.hide {
    display: none;
  }

  &.minimise {
    cursor: pointer;
    top: 100%;
    left: 30%;
    right: 30%;
    transform: translateY(-38px);
    opacity: 0.3;

    &:hover {
      opacity: 1;
    }
  }

  @media screen and (max-width: 800px) {
    left: 5%;
    right: 5%;
    height: 70%;

    &.minimise {
      left: 10%;
      right: 10%;
      width: 80%;
    }
  }

  .app-title {
    text-align: center;
    color: rgb(181,181,181);
    padding: 5px 0;
    margin: 0;
    text-shadow: 1px 1px 0 rgb(30,30,30);
    background: rgb(65,65,65);
    background: linear-gradient(0deg, rgb(65,65,65) 0%, rgb(54,54,54) 100%);
  }

  .app-body {
    overflow-y: auto;
    height: calc(100% - 38px - 2em);
    line-height: 25px;
    padding: 1em;
    ${p => `background: ${p.colour};`}
  }
`

const Controls = styled.div`
  display: flex;
  justify-content: space-between;
  position: absolute;
  left: 10px;
  top: 10px;
  width: 68px;
`

const Control = styled.button`
  padding: 0;
  margin: 0;
  cursor: pointer;
  width: 18px;
  height: 18px;
  border-radius: 50px;
  background: rgb(104,107,109);
  border: 0;
  outline: 0;
  transition: all .1s;

  svg {
    height: 8px;
    width: 8px;
    disaply: none;
    visibility: hidden;
    padding: 0px 0 0 5px;
  }

  &:hover {
    svg {
      display: block;
      visibility: visible;
    }
  }

  &.close {
    svg {
      fill: rgb(151,4,12);
    }

    &:hover {
      background: rgb(252,87,84);
    }
  }

  &.minimise {
    svg {
      fill: rgb(152,87,18);
    }

    &:hover {
      background: rgb(254,192,65);
    }
  }

  &.full {
    svg {
      fill: rgb(14,107,14);
    }

    &:hover {
      background: rgb(53,205,75);
    }
  }
`

export { AppBorderStyles, Controls, Control }

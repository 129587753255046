import styled from 'styled-components'

const LayoutStyles = styled.div`
  margin: 0 auto;
  max-width: 1200px;
  padding: 1em 0;
  position: relative;

  @media screen and (max-width: 1200px) {
    padding: 1em;
  }
`

export default LayoutStyles

import 'normalize.css'
import React from 'react'
import GlobalStyles from '../styles/GlobalStyles'
import LayoutStyles from '../styles/LayoutStyles'
import Nav from '../components/Nav'
import SEO from '../components/Seo'

export default function Layout ({ children }) {
  return (
    <>
      <SEO title='Daniel Swaine' />
      <GlobalStyles />
      <Nav />
      <LayoutStyles>
        {children}
      </LayoutStyles>
    </>
  )
}

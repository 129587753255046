import { createGlobalStyle } from 'styled-components'

const GlobalStyles = createGlobalStyle`
  html,
  body {
    height: 100%;
    margin: 0;
    padding: 0;
  }

  body,
  input,
  select,
  textarea {
    background: $white;
    color: #333;
    font: 15px/28px Helvetica, Arial, sans-serif;
    height: 100%;
  }

  strong {
      font-weight: 700;
  }

  em {
      font-style: italic;
  }

  img {
      max-width: 100%;
      height: auto;
  }

  p {
      margin: 0 0 1.1em 0;
  }

  ul {
    padding-left: 40px;
    margin-bottom:1em;
  }

  li, ol, ul {
    list-style: circle;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: 'Montserrat', sans-serif;
    line-height: 110%;
    margin: 0 0 .4em;
  }

  h1 {
    font-size: 3em;
    line-height: 150%;
    margin: 0 0 .3em;
  }

  h2 {
    font-size: 2.4em;
    line-height: 140%;
    margin-bottom: 20px;
  }

  h3 {
    font-size: 1.8em;
    line-height: 130%;
    margin-bottom: 20px;
  }

  h4 {
    font-size: 1.2em;
    margin: 0 0 16px;
  }

  h5 {
    font-size: 1em;
    margin: 0 0 16px 0;
  }

  h6 {
    font-size: 0.3535rem;
  }

  a {
    color: #b58900;
    border-bottom: 1px dotted #b58900;
    text-decoration: none;
  }

  @media screen and (max-width: 1000px) {
    body {
      font-size: 15px;
    }
  }

  @media screen and (max-width: 900px) {
    h1 {
      font-size: 2.4em;
      line-height: 160%;
    }

    h2 {
      font-size: 1.8em;
      line-height: 140%;
    }

    h3 {
      font-size: 1.6em;
    }
  }

  @media screen and (max-width: 650px) {
    h1 {
      font-size: 1.8em;
    }

    h2 {
      font-size: 1.4em;
    }

    h3 {
      font-size: 1.3em;
    }

    h4 {
      font-size: 1em;
    }

    h5 {
      font-size: .8em;
    }
  }

  @media screen and (max-width: 320px) {
    h1 {font-size: 1.6em}
    h2 {font-size: 1.3em}
  }
`

export default GlobalStyles

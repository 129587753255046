import React, { useState } from 'react'
import { AppBorderStyles, Controls, Control } from '../styles/AppBorderStyles'
import styled from 'styled-components'

const AppWrapper = styled.div`${AppBorderStyles};`

function controlClassNames (full, closed, minimised) {
  return [
    full ? 'full' : '',
    closed ? 'hide' : '',
    minimised ? 'minimise' : ''
  ].join(' ')
}

export default function AppBorder (props) {
  const [closed, setClosed] = useState(false)
  const [full, setFull] = useState(false)
  const [minimised, setMinimised] = useState(false)

  return (
    <AppWrapper colour={props.colour}
      className={controlClassNames(full, closed, minimised)}
      onClick={() => minimised && setMinimised(false) }>
      <div className='app-title'>
        <Controls>
          <Control onClick={() => setClosed(true)} className='close'>
            <svg viewBox='0 0 329.269 329' xmlns='http://www.w3.org/2000/svg'><path d='M194.8 164.77L323.013 36.555c8.343-8.34 8.343-21.825 0-30.164-8.34-8.34-21.825-8.34-30.164 0L164.633 134.605 36.422 6.391c-8.344-8.34-21.824-8.34-30.164 0-8.344 8.34-8.344 21.824 0 30.164l128.21 128.215L6.259 292.984c-8.344 8.34-8.344 21.825 0 30.164a21.266 21.266 0 0015.082 6.25c5.46 0 10.922-2.09 15.082-6.25l128.21-128.214 128.216 128.214a21.273 21.273 0 0015.082 6.25c5.46 0 10.922-2.09 15.082-6.25 8.343-8.34 8.343-21.824 0-30.164zm0 0'/></svg>
          </Control>
          <Control onClick={() => setMinimised(!minimised)} className='minimise'>
            <svg viewBox='0 -192 426.667 426' xmlns='http://www.w3.org/2000/svg'><path d='M405.332 43h-384C9.559 43 0 33.441 0 21.668 0 9.891 9.559.332 21.332.332h384c11.777 0 21.336 9.559 21.336 21.336 0 11.773-9.559 21.332-21.336 21.332zm0 0'/></svg>
          </Control>
          <Control onClick={() => setFull(!full)} className='full'>
            <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 447.308 447.308'><path d='M426.652 0H263.368c-8.364.03-15.892 5.053-19.082 12.731-3.175 7.755-1.42 16.609 4.474 22.503l35.676 35.676-213.51 213.525L35.25 248.76c-5.909-5.909-14.779-7.649-22.503-4.475C5.037 247.476.014 255.004.014 263.368L0 426.668c.014 11.404 9.251 20.64 20.64 20.64h163.313c8.366-.014 15.893-5.036 19.083-12.716a20.695 20.695 0 00-4.473-22.518l-35.676-35.691 213.494-213.511 35.691 35.678a20.706 20.706 0 0022.517 4.503c7.681-3.221 12.717-10.732 12.717-19.098v-163.3C447.307 9.252 438.072 0 426.652 0z'/></svg>
          </Control>
        </Controls>
        { props.title }
      </div>
      <div className='app-body'>
        { props.children }
      </div>
    </AppWrapper>
  )
}

import React from 'react'
import styled from 'styled-components'

const BurgerStyles = styled.div`
  width: 2rem;
  height: 2rem;
  display: none;

  @media (max-width: 768px) {
    display: flex;
    justify-content: space-around;
    flex-flow: column nowrap;
  }

  div {
    width: 2rem;
    height: 0.25rem;
    background: ${({ open }) => open ? 'rgb(252,87,84)' : 'white'};
    border-radius: 10px;
    transform-origin: 1px;
    transition: all 0.3s linear;

    &:nth-child(1) {
      transform: ${({ open }) => open ? 'rotate(45deg)' : 'rotate(0)'};
    }

    &:nth-child(2) {
      opacity: ${({ open }) => open ? 0 : 1};
    }

    &:nth-child(3) {
      transform: ${({ open }) => open ? 'rotate(-45deg)' : 'rotate(0)'};
    }
  }
`

export default function Burger (props) {
  return (
    <BurgerStyles open={props.open} onClick={props.onClick}>
      <div />
      <div />
      <div />
    </BurgerStyles>
  )
}
